/*------ 23. Blog Details Page  -------*/

.blog-details-top {
  .blog-details-img {
    position: relative;
    img {
      width: 100%;
    }
    .video-icon {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;

      transform: translateY(-50%);
      text-align: center;
      a {
        font-size: 25px;
        line-height: 61px;

        display: inline-block;

        width: 60px;
        height: 60px;

        color: #fff;
        border-radius: 50px;
        background-color: #ff0000;
        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }
  .blog-details-content {
    margin-top: 30px;
    .blog-meta-2 {
      display: flex;
      ul {
        li {
          font-size: 14px;
          line-height: 1;

          position: relative;

          display: inline-block;

          margin: 0 17px 0 0;

          list-style: outside none none;

          letter-spacing: 1px;
          text-transform: uppercase;

          color: #333;
          &::before {
            position: absolute;
            top: 6px;
            right: -13px;

            width: 5px;
            height: 2px;

            content: "";
            transition: all 0.4s ease 0s;

            background-color: #555;
          }
          &:last-child::before {
            display: none;
          }
          a {
            color: #333;
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 500;

      margin: 8px 0 20px;
      @media #{$lg-layout} {
        font-size: 22px;
      }
      @media #{$md-layout} {
        font-size: 22px;
      }
      @media #{$xs-layout} {
        font-size: 18px;
      }
    }
    p {
      line-height: 26px;

      margin: 0;
    }
    blockquote {
      font-size: 18px;
      font-weight: 500;
      font-style: italic;
      line-height: 34px;

      margin: 25px 0 26px;

      color: #333;
      @media #{$lg-layout} {
        font-size: 17px;
      }
      @media #{$md-layout} {
        font-size: 17px;
      }
      @media #{$xs-layout} {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

.dec-img-wrapper {
  margin-top: 50px;
  @media #{$xs-layout} {
    margin-top: 30px;
  }
  .dec-img {
    img {
      width: 100%;
    }
    &.mb-50 {
      @media #{$xs-layout} {
        margin-bottom: 30px;
      }
    }
  }
}

.tag-share {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 30px 0 30px;
  @media #{$xs-layout} {
    display: block;

    margin: 20px 0 25px;
  }
  .dec-tag {
    @media #{$xs-layout} {
      margin-bottom: 10px;
    }
    ul {
      display: flex;
      li {
        position: relative;

        margin-right: 10px;
        a {
          font-size: 15px;

          text-transform: capitalize;
        }
      }
    }
  }
  .blog-share {
    display: flex;
    align-items: center;
    span {
      font-size: 15px;

      text-transform: capitalize;
    }
    ul {
      display: flex;
      li {
        margin-left: 10px;
        a {
          font-size: 16px;
        }
      }
    }
  }
}

.next-previous-post {
  display: flex;
  justify-content: space-between;

  padding: 18px 0 17px;

  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  a {
    font-size: 15px;

    text-transform: capitalize;
  }
}

.blog-comment-wrapper {
  @media #{$xs-layout} {
    &.mt-55 {
      margin-top: 25px;
    }
  }
  .blog-dec-title {
    font-size: 20px;
    font-weight: 600;

    margin: 0;

    text-transform: uppercase;
    @media #{$xs-layout} {
      font-size: 18px;
    }
  }
  .single-comment-wrapper {
    display: flex;
    @media #{$xs-layout} {
      display: block;
      &.mt-35 {
        margin-top: 20px;
      }
      &.mt-50 {
        margin-top: 30px;
      }
    }
    .blog-comment-img {
      flex: 0 0 120px;

      margin-right: 28px;
      @media #{$xs-layout} {
        margin-right: 28px;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        @media #{$xs-layout} {
          width: auto;
        }
      }
    }
    .blog-comment-content {
      h4 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;

        margin: 0;
      }
      span {
        font-size: 15px;

        display: block;

        margin: 6px 0 8px;
      }
      p {
        font-size: 14px;

        margin-bottom: 10px;
      }
      .blog-details-btn {
        a {
          font-size: 14px;
          font-weight: 500;

          text-transform: capitalize;
        }
      }
    }
    &.ml-120 {
      @media #{$xs-layout} {
        margin-left: 0;
      }
    }
  }
}

.blog-reply-wrapper {
  @media #{$xs-layout} {
    &.mt-50 {
      margin-top: 30px;
    }
  }
  .blog-dec-title {
    font-size: 20px;
    font-weight: 600;

    margin: 0;

    text-transform: uppercase;
    @media #{$xs-layout} {
      font-size: 18px;
    }
  }
  .blog-form {
    margin-top: 35px;
    @media #{$xs-layout} {
      margin-top: 20px;
    }
    .leave-form {
      input {
        height: 45px;
        margin-bottom: 30px;
        padding-left: 20px;

        color: #333;
        border: 1px solid #eaeaea;
        background: transparent none repeat scroll 0 0;
        box-shadow: none;
      }
    }
    .text-leave {
      textarea {
        height: 250px;
        margin-bottom: 20px;
        padding-top: 20px;
        padding-left: 20px;

        color: #333;
        border: 1px solid #eaeaea;
        background: transparent none repeat scroll 0 0;
        box-shadow: none;
      }
      input {
        font-weight: 500;

        display: inline-block;

        width: inherit;
        height: inherit;
        margin-top: 10px;
        padding: 14px 30px 13px;

        cursor: pointer;
        letter-spacing: 1px;

        color: #fff;
        border: medium none;
        background-color: $theme-color;
        &:hover {
          background-color: #333;
        }
      }
    }
  }
}

.nav-style-4.owl-carousel:hover .owl-nav div {
  visibility: visible;

  opacity: 1;
}

.timeline {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 700px;
  position: relative;
}
.timeline__content-title {
  font-weight: normal;
  font-size: 55px;
  //margin: -10px 0 0 0;
  transition: .4s;
  padding: 2px 17px 12px 28px;
  box-sizing: border-box;
  color: #fff;
  background: rgba(0,0,0,0.8);
  line-height: 55px;
  display: flex; /* Add this line */
  align-items: flex-start; /* Add this line */
}

.timeline__content-desc {
  margin: 0;
  font-size: 18px;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: normal;
  line-height: 25px;
}

.timeline:before {
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  content: "";
  background: rgba(255, 255, 255, 0.7);
}
@media only screen and (max-width: 767px) {
  .timeline:before {
    display:none;
  }
}
.timeline-item {
  padding: 40px 0;
  opacity: .3;
  filter: blur(2px);
  transition: .5s;
  box-sizing: border-box;
  width: calc(50% - 40px);
  display: flex;
  position: relative;
  transform: translateY(-80px);
}
.timeline-item:before {
  content: attr(data-text);
  letter-spacing: 0;
  width: 100%;
  position: absolute;
  color: #ffffff;
  font-size: 16px;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  top: 70%;
  margin-top: -5px;
  padding-left: 15px;
  opacity: 0;
  right: calc(-100% - 56px);
}
.timeline-item:nth-child(even) {
  align-self: flex-end;
}
.timeline-item:nth-child(even):before {
  right: auto;
  text-align: right;
  left: calc(-100% - 56px);
  padding-left: 0;
  border-left: none;
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  padding-right: 15px;
}
.timeline-item--active {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0px);
}
.timeline-item--active:before {
  top: 50%;
  transition: .3s all .2s;
  opacity: 1;
}
.timeline-item--active .timeline__content-title {
  //margin: -55px 0 20px 0;
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .timeline-item {
    align-self: baseline !important;
    width: 100%;
    padding: 0 30px 150px 80px;
  }
  .timeline-item:before {
    left: 10px !important;
    padding: 0 !important;
    top: 50px;
    text-align: center !important;
    width: 60px;
    border: none !important;
  }
  .timeline-item:last-child {
    padding-bottom: 40px;
  }
}
.timeline__img {
  max-width: 100%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
}
.timeline-container {
  width: 100%;
  position: relative;
  padding: 80px 0;
  transition: .3s ease 0s;
  background-attachment: fixed;
  background-size: cover;
}
.timeline-container:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(99, 99, 99, 0.8);
  content: "";
}
.timeline-header {
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}
.timeline-header__title {
  color: #fff;
  font-size: 46px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}
.timeline-header__subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  letter-spacing: 5px;
  margin: 10px 0 0 0;
  font-weight: normal;
}

.buy_now {

  display: block;
  color: white;
  background-color: $theme-color;
  text-align: center;

  &:hover {
    color: white;
    background-color: red;
  }

  width: 50%;
  position: relative; /* Add this property */
  left: 25%; /* Add this property */


  a {
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding-bottom: 2px;

    text-align: center;

    color: white;
    border-bottom: 1px solid #cccccc;
    //&:hover {
    //  color: white;
    //  background-color: black;
    //  border-bottom: 1px solid black;
    //}
  }
}

/* Style for the logo/text */
.overlay-content {
  position: absolute;
  bottom: 80%;
  left: 20px;
  padding: 5px; /* Adjust padding as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and transparency as needed */
  color: white; /* Adjust the text color as needed */
  opacity: 0; /* Initially set opacity to 0 */
  transition: opacity 1s; /* Add a transition for a smooth appearance */
}

.overlay-content.show {
  opacity: 1; /* Change opacity to 1 when the 'show' class is added */
}

.demo-footer {
  padding: 60px 0;
  text-align: center;
}
.demo-footer a {
  color: #999;
  display: inline-block;
}
